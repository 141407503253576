<template>
  <div>
    <div
      id="headsupwrap"
      :class="`ap-headsup-sevModule closed ${displayLeftSide ? 'displayLeftSide' : ''}`"
    >
      <div
        id="headsup-content-id"
        class="headsup-content"
      >
        <span class="ieTopShadow" />
        <div
          id="headsup-success"
          class="sentSuccess"
          style="height:0px;overflow:hidden;opacity:0.0;"
        >
          <div class="sentSuccess__inner">
            <div class="tick" />
            <div class="message" />
          </div>
        </div>
        <div class="headsup-cover-shadow" />
        <div class="headsup-personalization cf">
          <div
            id="idclose-headsup"
            class="close-headsup"
          />
          <h1>{{ $t('HEADSUP_FROM_NAME') || $t('PROVIDER_NAME') || '*' }}</h1>
          <h2>{{ $t('HEADSUP_FROM_NAME_TITLE') }}</h2>
        </div>
        <div class="headsup-message">
          <div class="small-text">
            <p>{{ $t('HEADSUP_PROMPT_MESSAGE_TEXT') }}</p>
          </div>
          <div class="large-text">
            <p>{{ $t('HEADSUP_SHORT_DESCRIPTION_TEXT') }}</p>
          </div>
          <div class="headsup-button">
            <a
              :id="ctaButtonId"
              class="adent-open-button"
            >
              <h4>{{ $t('HEADSUP_CTA_BUTTON_TEXT') }}</h4>
            </a>
          </div>
        </div>
        <span class="ieBottomShadow" />
      </div>
      <div
        id="headsup-image-id"
        class="headsup-image"
      >
        <div class="headsup-number" />
        <img
          id="imgSrc"
          :src="$t('PROVIDER_DENTIST_PORTRAIT_URL') || $t('PLACEHOLDER_PROVIDER_DENTIST_PORTRAIT_URL')"
        >
        <div class="iemask" />
      </div>
      <div class="headsup-arrow" />
    </div>
    <div style="display:none;">
      <img :src="closeButtonHover">
    </div>
  </div>
</template>

<script>
  import headsupApFn from './assets/js/headsup'

  import { loadLanguageAsync } from '@/i18n'
  import validationApiServices from '@/views/widgets/services/validation_endpoints'

  export default {
    name: 'HeadsupComponent',

    components: {
    },

    data: () => ({
      closeButtonHover: 'https://f6d8b2eeb23e2fe14a6a-2d01bb8c606da8c715207a3eb162b3e6.ssl.cf2.rackcdn.com/headsup-close-hover.svg',
      ctaButtonId: 'proactive-headsup-cta-button-id',
      imageholderId: 'headsup-image-id',
      ctaButtonColor: '#f5326e',
      imageholderColor: '#46cda5',
      displayLeftSide: true,
      widgetType: '',
      landingPageType: '',
      widgetUuid: '',
      clinicWidgetId: '',
    }),

    computed: {
    },

    created () {
      // NOTE: For developers' common memory, this component assumes the
      //  global "background: none;" style when loaded into an iframe.
    },

    async mounted () {
      const clinicWidgetId = this.$route.query.provider
      this.clinicWidgetId = clinicWidgetId
      try {
        const response = await validationApiServices.getClinicLocales({ clinicWidgetId })
        this.widgetUuid = response.data.data.attributes.widget_uuid
        this.widgetType = response.data.data.attributes.widget_type
        this.landingPageType = response.data.data.attributes.landing_page_type
        const messages = response.data.data.attributes.clinic_widget_locales_as_json
        const fallbackLanguage = response.data.data.attributes.fallback_locales_language
        await loadLanguageAsync({ clinicWidgetId, messages, fallbackLanguage })
      } catch (e) {
        throw e
      }

      let _left = this.$route.query.left
      if (_left !== undefined) {
        if (_left === 'true') {
          this.displayLeftSide = true
        } else if (_left === 'false') {
          this.displayLeftSide = false
        }
      }

      try {
        this.setCustomThemeColor()
      } catch (e) {
        // pass
      }

      window.pm = function (data) {
        window &&
          window.parent &&
          window.parent.postMessage &&
          (typeof window.parent.postMessage === 'function') &&
          // If everything is valid send a 'postMessage'.
          // NOTE: This sends a message to the parent page, so only has an
          //   effect when embedded as an iframe. Can cause some confusion
          //   during development, when working in the "raw page".
          window.parent.postMessage(data, '*')
      }
      headsupApFn.init()
    },

    methods: {
      setCustomThemeColor () {
        const button = document.getElementById(this.ctaButtonId)
        const imageholder = document.getElementById(this.imageholderId)

        const ctaButtonColorLookup = 'PROVIDER_THEME_CTA_BUTTON_COLOR'
        const customCtaButtonColor = this.$t(ctaButtonColorLookup)
        if (customCtaButtonColor && customCtaButtonColor !== ctaButtonColorLookup) {
          button.style.backgroundColor = customCtaButtonColor
        }
        const imageholderColorLookup = 'PROVIDER_THEME_IMAGEHOLDER_COLOR'
        const customImageholderColor = this.$t(imageholderColorLookup)
        if (customImageholderColor && customImageholderColor !== imageholderColorLookup) {
          imageholder.style.background = customImageholderColor
        }
      },
    },
  }
</script>

<style scoped>
    @import './assets/css/headsup.css';
</style>
