var headsupApFn = {
    init: function () {
        // inject styles
        var css = 'h1 { background: none; }'
            var head = document.head || document.getElementsByTagName('head')[0]
            var style = document.createElement('style')
        style.type = 'text/css'
        if (style.styleSheet) {
          style.styleSheet.cssText = css
        } else {
          style.appendChild(document.createTextNode(css))
        }
        head.appendChild(style)

        // setup variables
        var d = document.getElementById('headsupwrap')
        var dImage = document.getElementById('headsup-image-id')
        var opened = false
        var loadTime = Date.now() - 100
        var hideOnMobile = document.getElementsByClassName('hideOnMobile').length
        var hideOnTablet = document.getElementsByClassName('hideOnTablet').length

        var mobileDev = false
        var tabletDev = false
        if (typeof window.matchMedia === 'function') {
          if (window.matchMedia('only screen and (min-device-width : 375px) and (max-device-width : 667px)').matches) {
            mobileDev = true
          } else if (window.matchMedia('screen and (max-device-width : 414px)').matches) {
            mobileDev = true
          } else if (window.matchMedia('only screen and (min-device-width : 320px) and (max-device-width : 568px)').matches) {
            mobileDev = true
          } else if (window.matchMedia('screen and (max-device-width : 375px)').matches) {
            mobileDev = true
          } else if (window.matchMedia('only screen and (min-device-width : 768px) and (max-device-width : 1024px)').matches) {
            // iPad in portrait & landscape
            tabletDev = true
          } else if (window.matchMedia('only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2)').matches) {
            // Retina iPad in portrait & landscape
            tabletDev = true
          }
        }

        if ((mobileDev && hideOnMobile > 0) || (tabletDev && hideOnTablet > 0)) {
          return
        }

        if (mobileDev || tabletDev) {
          setTimeout(function () {
            window.pm({ height: '190px' })
          }, 0)
        }

        // slide in headsup
        setTimeout(function () {
            d.className = d.className + ' slideUp'
            window.pm({ showHeadsup: true, opacity: '0.0' })

            // add pulse css class
            setTimeout(function () {
                dImage.className = dImage.className + ' pulse'
            }, 1500)
        }, Math.max(0, 2500 - loadTime))

        // click event - close
        var elClose = document.getElementById('idclose-headsup')
        elClose.onclick = function () {
            d.className = d.className + ' slideDowner'

            const timeout1 = 450 // 0.45sec from animation duration in css
            const timeout2 = timeout1 + 450
            const timeout3 = timeout2 + 1000
            setTimeout(function () {
                window.pm({ close: true })
            }, timeout1)
            setTimeout(function () {
                window.pm({ hideHeadsup: true })
                d.classList.remove('slideUp', 'slideDowner')
                opened = false
                if (!d.classList.contains('closed')) { d.classList.add('closed') }
              }, timeout2)
            setTimeout(function () {
                d.className = d.className + ' slideUp'
                window.pm({ showHeadsup: true, opacity: '1.0' })
            }, timeout3)
        }

        // cta click event
        var elCtaBtnLink = document.getElementById('proactive-headsup-cta-button-id')
        if (elCtaBtnLink) {
            elCtaBtnLink.onclick = function () {
                window.pm({ openWidget: true })
                const timeout1 = 2300 // 0.45sec from animation duration in css
                const timeout2 = timeout1 + 450
                const timeout3 = timeout1 + 1000
                setTimeout(function () {
                    d.className += ' slideDowner'
                }, timeout1)
                setTimeout(function () {
                    window.pm({ hideHeadsup: true })
                    d.classList.remove('slideUp', 'slideDowner')
                    opened = false
                    if (!d.classList.contains('closed')) { d.classList.add('closed') }
                }, timeout2)
                setTimeout(function () {
                    d.className = d.className + ' slideUp'
                    window.pm({ showHeadsup: true, opacity: '1.0' })
                }, timeout3)
            }
        }

        // hover event - hover to slide up extended message
        // this handler will be executed only once when the cursor moves over the unordered list
        d.onmouseover = function () {
            if (opened) {
              return
            }

            window.pm({ openHeadsup: true })

            opened = true
            var borderless = document.getElementsByClassName('headsup-image-borderless')
            if (borderless.length > 0) {
                dImage.className = 'headsup-image-borderless'
            } else {
                dImage.className = 'headsup-image'
            }
            var wrap = document.getElementById('headsupwrap')
            if (wrap.classList.contains('closed')) {
                wrap.classList.remove('closed')
            }
        }
    },
}

export default headsupApFn
